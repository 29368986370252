

































import { Component, PropSync, Ref, Watch, Prop } from 'vue-property-decorator';
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import JobRoleAutocomplete from "@/components/common/job-role/JobRoleAutocomplete.vue";
import { JobRoleDescription } from "@/components/vessel/enquiry/VesselEnquiryJobRoleTable.vue";
import { JobRoleModel } from "@/api/generated";
import Discriminator from '../../types/Discriminator';

@Component({ components: { JobRoleAutocomplete } })
export default class VesselEnquiryJobRoleAddDialog extends Validation {
  @Ref() private readonly form!: VForm;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;

  private selectedJobRole: JobRoleModel | null = null;
  private model: JobRoleDescription = {
    jobRoleId: "",
    jobRole: "",
    quantity: 1,
    crewMemberId: "",
    startDate: "",
    endDate: "",
    vesselEnquiryId: ""
  };

  @Watch("selectedJobRole")
  private onSelectedJobRoleChanged() {
    if (!this.selectedJobRole) {
      return;
    }

    this.model = {
      jobRoleId: this.selectedJobRole.jobRoleId,
      jobRole: this.selectedJobRole.name,
      crewMemberId: undefined,
      vesselEnquiryId: undefined,
      quantity: 1,
      startDate: "",
      endDate: ""
    };
  }

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private onAddJobRole() {
    if (!this.form.validate()) {
      return;
    }

    this.$emit("job-role-added", this.model);
  }
}
