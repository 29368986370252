





























import { Vue, Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { CrewMemberVesselEnquiryCreate } from "@/api/generated";
import nameof from "@/utility/nameof";

export interface JobRoleDescription extends CrewMemberVesselEnquiryCreate {
  jobRole: string;
  quantity: number;
}

@Component
export default class VesselEnquiryJobRoleTable extends Vue {
  @Prop(Boolean) isCompaniesTable?: boolean;
  @Prop({ type: Array, required: true })
  private items!: JobRoleDescription[];

  private headers: Array<DataTableHeader<JobRoleDescription>> = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Job Role", value: nameof<JobRoleDescription>("jobRole") },
  ];

  private get jobRoleItems() {
    const jobRoles: Array<JobRoleDescription> = [];

    if (!this.items?.length) {
      return jobRoles;
    }

    const map: Map<string, JobRoleDescription> = new Map<
      string,
      JobRoleDescription
    >();

    for (const item of this.items) {
      if (!item.jobRoleId) {
        continue;
      }

      map.set(item.jobRoleId, item);
    }

    map.forEach(x => {
      jobRoles.push({
        jobRoleId: x.jobRoleId ?? "",
        jobRole: x.jobRole,
        quantity:
          this.items.filter(y => x.jobRoleId === y.jobRoleId)?.length ?? 0
      });
    });

    return jobRoles;
  }
}
