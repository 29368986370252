

































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import CertificateTypeAutocomplete from "@/components/common/certificate-type/CertificateTypeAutocomplete.vue";
import { RequiredCertificates } from "../vessel/enquiry/VesselEnquiryCertificateCreateTable.vue";
import { CertificateTypeModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';

@Component({ components: { CertificateTypeAutocomplete } })
export default class VesselEnquiryCertificateAddDialog extends Validation {
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  @Ref() private readonly form!: VForm;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;

  private selectedCertificateType: CertificateTypeModel | null = null;

  private model: RequiredCertificates = {
    name: ""
  };

  @Watch("selectedCertificateType")
  private onselectedCertificateType() {
    if (!this.selectedCertificateType) {
      return;
    }

    this.model = {
      name: this.selectedCertificateType.name,
      certificateTypeId: this.selectedCertificateType.certificateTypeId
    };
  }

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private onAddCertificate() {
    if (!this.form.validate()) {
      return;
    }

    this.$emit("certificate-added", this.model);
    this.syncedDialog = false;
  }
}
