





























import { Vue, Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { CertificateTypeVesselEnquiryCreateModel } from "@/api/generated";
import nameof from "@/utility/nameof";

export interface RequiredCertificates
  extends CertificateTypeVesselEnquiryCreateModel {
  name: string;
}

@Component
export default class VesselEnquiryCertificateCreateTable extends Vue {
  @Prop({ type: Array, required: true })
  private items!: Array<CertificateTypeVesselEnquiryCreateModel>;

  private headers: Array<
    DataTableHeader<CertificateTypeVesselEnquiryCreateModel>
  > = [
    { text: "Actions", value: "actions" },
    { text: "Certificate Name", value: nameof<RequiredCertificates>("name") }
  ];
}
